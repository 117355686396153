var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.componentClases },
    [
      _c("vs-input", {
        key: _vm.index,
        staticClass: "w-full",
        attrs: { type: "text", disabled: _vm.disabled },
        model: {
          value: _vm.text,
          callback: function($$v) {
            _vm.text = $$v
          },
          expression: "text"
        }
      }),
      _c("CharCounter", {
        attrs: {
          item: _vm.text,
          maxLength: _vm.maxLength,
          isInvisible: _vm.isCounterInvisible
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }