<template>
    <div :class="componentClases" >
        <vs-input  
            :key="index"
            class="w-full"
            type="text"                                          
            v-model="text"
            :disabled="disabled"
        />  
        <CharCounter
            :item="text"
            :maxLength=maxLength
            :isInvisible=isCounterInvisible
        ></CharCounter>  
    </div>
</template>

<script>
import CharCounter from "@/modules/Shared/Components/form/CharCounter.vue";


export default { 
    name:'InputCharCounter', 
    components:{
        CharCounter,
    },   
    props: {
        index:{
            type:Number,
            require:false
        },
        ModelValue:{
            type:String,
            required:true,
            default:""
        },
        maxLength: {
            type: Number,
            required: true,
            default: 100
        },
        componentClases :{
            type: String,
            default : "w-4/5",
            required:false,     
        },
        disabled:{
            type: Boolean,
            default : false,
            required: false,     
        },
        isCounterInvisible: {
            type: Boolean,
            required: false,
            default: false
        },     
    },
    data(){
        return {
            text:this.ModelValue
        }
    },
    watch: {
        text() {
            this.$emit('input', this.text)
        }
    },

}
</script>